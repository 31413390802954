<template lang="pug">
img(:src="require(`@/assets/admin/img/filter-banner-${lang}.png`)")
</template>
<script>
  import { getLang } from '@/router/utils';

  export default {
    computed: {
      lang() {
        return getLang();
      },
    },
  };
</script>
